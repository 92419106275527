import './newsletter-subscription.scss';
import axios from 'axios';
import { createElementFromHTML, getOffset, smoothScrollTo } from '../../utilities/js/helper';

class NewsletterSubscription {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-newslettersubscription',
            scrollOffset: 0
        };

        this.settings = Object.assign({}, defaults, options);
        this.$newsletterSubscription = element;
        this.$form = this.$newsletterSubscription.querySelector('[data-newslettersubscription="form"]');
        this.$header = document.querySelector('.site-header');
        this.$newsletterFormWrapper = '';
    }

    initialize () {
        const uri = this.$newsletterSubscription.getAttribute('data-href');

        if (uri) {
            axios({
                method: 'GET',
                url: uri
            })
                .then((result) => {
                    if (result && result.data) {
                        const $result = createElementFromHTML(result.data);
                        this.$newsletterSubscription.replaceWith($result);
                        this.$newsletterFormWrapper = $result.querySelector('[data-newslettersubscription="form"]');
                        this.initForm(this.$newsletterFormWrapper);
                    }
                })
                .catch((error) => {
                    throw Error(error);
                });
        } else if (this.$form) {
            this.$newsletterFormWrapper = this.$form;
            this.initForm(this.$form);
        }
    }

    initForm ($wrapper) {
        const $form = $wrapper.querySelector('form');

        $form.addEventListener('submit', (e) => {
            this.submitForm($form);
            e.preventDefault();
        });
    }

    submitForm ($form) {
        const $button = $form.querySelector('button[type="submit"]');
        const formMethod = $form.getAttribute('method');
        const formAction = $form.getAttribute('action');
        const formData = new FormData($form);

        if ($button !== null) {
            $button.setAttribute('disabled', true);
        }

        axios({
            method: formMethod,
            url: formAction,
            data: formData
        })
            .then((result) => {
                if (result && result.data) {
                    const $result = createElementFromHTML(result.data);

                    const $resultContent = $result.innerHTML;
                    let $resultContainer = null;

                    if ($resultContent) {
                        let headerHeight = 0;
                        if (this.$header !== null) {
                            headerHeight = parseInt(this.$header.offsetHeight);
                        }

                        if ($result.getAttribute('data-newslettersubscription') === 'success') {
                            let successScrollPosition = 0;

                            if (this.$newsletterFormWrapper.getAttribute('data-formtarget')) {
                                const targetId = this.$newsletterFormWrapper.getAttribute('data-formtarget');
                                const $target = document.getElementById(targetId);

                                if ($target) {
                                    successScrollPosition = getOffset($target);
                                }

                                $target.innerHTML = $resultContent;
                                $resultContainer = $target;
                            } else {
                                this.$newsletterFormWrapper.innerHTML = $resultContent;
                                successScrollPosition = getOffset(this.$form);
                                $resultContainer = this.$newsletterFormWrapper;
                                this.initForm($resultContainer);
                            }

                            smoothScrollTo(0, successScrollPosition.top - headerHeight - this.settings.scrollOffset);

                            const contentUpdateEvent = new CustomEvent('content.loaded', {
                                view: window,
                                bubbles: true,
                                cancelable: false,
                                detail: {
                                    $context: this.$newsletterFormWrapper
                                }
                            });

                            window.dispatchEvent(contentUpdateEvent);
                        } else {
                            this.$newsletterFormWrapper.innerHTML = $resultContent;
                            this.scrollToFirstError(this.$newsletterFormWrapper);
                            this.initForm(this.$newsletterFormWrapper);
                        }
                    }
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }

    scrollToFirstError ($form) {
        let headerHeight = 0;
        if (this.$header !== null) {
            headerHeight = parseInt(this.$header.offsetHeight);
        }
        const $firstError = $form.querySelectorAll('.has--error')[0];
        if ($firstError) {
            const errorScrollPosition = getOffset($firstError);
            smoothScrollTo(0, errorScrollPosition.top - headerHeight - this.settings.scrollOffset);
        }
    }
}

export { NewsletterSubscription };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-newslettersubscription="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new NewsletterSubscription($root);
                $rootAPI.initialize();
            });
        }
    }
});
